import Layout from "src/layout"
import { Column, Title } from "src/layout/styled"

const NotFoundPage: GatsbyPage = () => (
  <Layout theme="dark" title="Mentions légales">
    <Column>
      <Title>Mentions légales</Title>
      <h2>Editeur</h2>
      <p>
        SAS Agence Phare
        <br />
        SIRET : 812 528 610 00031
        <br />
        Siège social : 5 rue Ordener, 75018 Paris
        <br />
        Contact : <a href="mailto:francois@agencephare.com">francois@agencephare.com</a>
      </p>
      <h2>Développement et graphisme</h2>
      <p>
        <a href="https://premicesandco.com/" target="_blank" rel="noopener">
          Premices and co
        </a>
        <br />
        <a href="https://antoine.rousseau.im/" target="_blank" rel="noopener">
          Antoine Rousseau
        </a>
        <br />
        Photographies : Pixabay, AdobeStock, Agence Phare
      </p>
      <h2>Hébergement</h2>
      <p>
        <a href="https://www.netlify.com/" target="_blank" rel="noopener">
          Netlify
        </a>
        {" et "}
        <a href="https://www.contentful.com/" target="_blank" rel="noopener">
          Contentful
        </a>
      </p>
      <h2>Droits d’auteur et copyright</h2>
      <p>
        L’ensemble de ce site relève de la législation française et internationale sur le droit d’auteur et la propriété
        intellectuelle. Tous les droits de reproduction sont réservés, y compris pour les représentations
        iconographiques et photographiques. La reproduction de tout ou partie de ce site sur un support électronique
        quel qu’il soit est formellement interdite sauf autorisation expresse de l’Agence Phare. Pour d’autres
        utilisations, veuillez nous consulter.
      </p>
      <h2>Protection des données personnelles</h2>
      <p>
        Aucune information personnelle n’est collectée à votre insu, cédée à des tiers, utilisée à des fins non prévues.
      </p>
      <h2>Cookies</h2>
      <p>Le site n’utilise actuellement aucun cookie.</p>
      <h2>Responsabilité</h2>
      <p>
        Aucune autre garantie n’est accordée à l’utilisateur, auquel incombe l’obligation de formuler clairement ses
        besoins et le devoir de s’informer. Si des informations fournies par l’Agence Phare apparaissent inexactes, il
        appartiendra au client de procéder lui-même à toutes vérifications de la cohérence ou de la vraisemblance des
        résultats obtenus. L’Agence Phare ne sera en aucune façon responsable vis à vis des tiers de l’utilisation par
        l’utilisateur des informations (ou de leur absence) contenues sur son Internet.
      </p>
      <p>
        <br />
        <br />
        <br />
      </p>
      <p>Copyright 2020 – SAS Agence Phare</p>
    </Column>
  </Layout>
)

export default NotFoundPage
